import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "src/components/layout"
import Head from "src/components/Head"
import LicenseCategory from "../../components/clients/licenseCategory/LicenseCategory"
import "../license.scss"
import { useTranslation } from "react-i18next"
import BRANDS from "../../brands"

const ClientsMain = () => {
  const data = useStaticQuery(graphql`
    query UserAgreementsQueryBy {
      agreement: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(by/(rule/230801_rule|individual_license|policy))/" } }
        sort: {order: DESC, fields: [frontmatter___date] }
      ) {
        agreements: nodes {
          fields {
            slug
          }
          frontmatter {
            title
            category
            subcategory
          }
          id
        }
      }
      site: site {
        siteMetadata {
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  const AGREEMENTS_CATEGORIES = ["individual_license", "policy", "rule"]

  const { agreements } = data.agreement
  const { hotLine } = data.site.siteMetadata.countries.by
  const country = "by"
  const { t, i18n } = useTranslation()

  const agreementsCategories = {}

  AGREEMENTS_CATEGORIES.forEach((categoryName) => {
    const agreementsList = agreements.filter(
      (agreement) => agreement.frontmatter.subcategory === categoryName
    )

    if (agreementsList.length > 0) {
      const firstLicenseSlug = agreementsList[0].fields.slug

      agreementsCategories[categoryName] = {
        id: categoryName,
        title: `user-agreements.${categoryName}`,
        agreements: agreementsList,
        slug: firstLicenseSlug,
      }
    }
  })

  return (
    <Layout country={country} hotLine={hotLine}>
      <Head
        lang={country}
        title={t("head.index.title", { brand: BRANDS.ALLSPORTS.brand })}
        description={t("head.description", { brand: BRANDS.ALLSPORTS.brand })}
        slug="/"
      />
      <div className="license-header">
        <h2>{t("user-agreements.title")}</h2>
      </div>

      <div className="licenses">
        {Object.values(agreementsCategories).map((agreement) => {
          return (
            <LicenseCategory
              key={agreement.id}
              title={agreement.title}
              slug={agreement.slug}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default ClientsMain
